<script lang="ts" setup>
import { TuiIconButton, TuiTooltip } from '@clickbar/tailwindui-vue'
import { useDebounceFn, useStorage, useTimeoutFn } from '@vueuse/core'

import Sidebar from '@/Components/Sidebar/Sidebar.vue'
import { useHotKeys } from '@/Composables'

import type { Navigation } from '@/Types'

defineProps<{
  items: Navigation[]
}>()

const sidebarOpen = useStorage('sidebarOpen', true)
const sidebarFixed = useStorage('sidebarFixed', true)

useHotKeys({
  'shift+x': () => (sidebarFixed.value ? unfixSidebar() : fixSidebar()),
  'shift+c': () => (sidebarOpen.value ? closeSidebarImmediately() : openSidebarImmediately()),
})

const { start: registerHover, stop: cancelHover } = useTimeoutFn(
  () => {
    sidebarOpen.value = true
  },
  300,
  { immediate: false },
)

const closeSidebarAfterDelay = useDebounceFn(() => {
  if (sidebarFixed.value) return
  sidebarOpen.value = false
}, 300)

function closeSidebarImmediately() {
  if (sidebarFixed.value) return
  cancelHover()
  sidebarOpen.value = false
}

function closeSidebar() {
  if (sidebarFixed.value) return
  cancelHover()
  closeSidebarAfterDelay()
}

function openSidebarAfterDelay() {
  if (sidebarFixed.value) return
  registerHover()
}

function openSidebarImmediately() {
  if (sidebarFixed.value) return
  sidebarOpen.value = true
}

function fixSidebar() {
  sidebarFixed.value = true
  sidebarOpen.value = true
}

function unfixSidebar() {
  sidebarFixed.value = false
  sidebarOpen.value = false
}
</script>

<template>
  <div class="relative h-full" :class="{ 'mr-2': !sidebarFixed }">
    <div
      class="fixed z-20 h-full transition-all"
      :class="[
        sidebarOpen ? 'translate-x-0' : 'translate-x-[-16rem]',
        !sidebarFixed ? 'px-2 py-4' : '',
      ]"
      @mouseenter="openSidebarAfterDelay"
      @mouseleave="closeSidebar"
    >
      <Sidebar
        :items="items"
        :class="{
          'overflow-hidden rounded shadow-lg': !sidebarFixed,
        }"
      />

      <div
        v-if="sidebarFixed"
        class="group absolute inset-y-0 -right-1 z-40 w-2 cursor-w-resize"
        title="Navigation einklappen (⇧ + X)"
        @click="unfixSidebar"
      >
        <div
          class="absolute inset-y-0 left-1 w-0.5 bg-gray-100 group-hover:bg-gray-500 dark:bg-zinc-800"
        />
      </div>
    </div>

    <div
      v-if="!sidebarFixed"
      class="absolute bottom-4 right-0 z-50 transition-all"
      :class="[sidebarOpen ? 'translate-x-[19rem]' : 'translate-x-12']"
    >
      <TuiTooltip text="Navigation ausklappen (⇧ + X)">
        <TuiIconButton class="bg-primary-200 shadow-lg" @click="fixSidebar">
          <svg class="!h-4 !w-4" viewBox="0 0 16 16" fill="#6B6F76">
            <path
              d="M15 5.25A3.25 3.25 0 0 0 11.75 2h-7.5A3.25 3.25 0 0 0 1 5.25v5.5A3.25 3.25 0 0 0 4.25 14h7.5A3.25 3.25 0 0 0 15 10.75v-5.5Zm-3.5 7.25H7v-9h4.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2Zm-6 0H4.25a1.75 1.75 0 0 1-1.75-1.75v-5.5c0-.966.784-1.75 1.75-1.75H5.5v9Z"
            />
          </svg>
        </TuiIconButton>
      </TuiTooltip>
    </div>
  </div>

  <div class="h-0 transition-all" :class="[sidebarFixed ? 'w-64' : 'w-0']" />
</template>
