<script lang="ts" setup>
import { TuiBadge, TuiProgressLinear, showConfirmModal } from '@clickbar/tailwindui-vue'
import { toGermanString } from '@clickbar/utils'
import { usePage } from '@inertiajs/vue3'
import { useTransition, TransitionPresets } from '@vueuse/core'
import axios from 'axios'
import { differenceInSeconds, parseISO } from 'date-fns'
import { ref, computed } from 'vue'

import { useProcessUpdateListener } from '@/echo'
import { filterInPlace, formatShortDuration } from '@/Utils'

import IFluentPin12Filled from '~icons/fluent/pin12-filled'
import IFluentPin12Regular from '~icons/fluent/pin12-regular'
import IHeroiconsXMark from '~icons/heroicons/x-mark'
import IIconParkOutlineSleep from '~icons/icon-park-outline/sleep'
import IMaterialSymbolsCancel from '~icons/material-symbols/cancel'
import IMaterialSymbolsError from '~icons/material-symbols/error'
import IMdiCancel from '~icons/mdi/cancel'
import IPajamasTaskDone from '~icons/pajamas/task-done'
import IUimProcess from '~icons/uim/process'

const props = defineProps<{
  process: App.Data.Process.ProcessData
}>()

const emit = defineEmits<{
  close: []
}>()

useProcessUpdateListener(props.process.id, (newProcess) => Object.assign(props.process, newProcess))

const status = computed(() => computeStatusForProcess(props.process))

const progressValue = computed(
  () => (props.process.completed_units ?? 0) / (props.process.total_units ?? 1),
)

const tweenedProgessValue = useTransition(progressValue, {
  duration: 500,
  transition: TransitionPresets.easeOutQuad,
})

const page = usePage()

async function pin() {
  if (!page.props.pinnedProcesses.some((x) => x.id === props.process.id)) {
    // Do a bit of local-first stuff
    page.props.pinnedProcesses.push(props.process)
  }

  axios.post(route('processes.pin', props.process.id))
}

async function unpin() {
  filterInPlace(page.props.pinnedProcesses, (x) => x.id !== props.process.id)

  axios.post(route('processes.unpin', props.process.id))
}

const isPinned = computed(() => page.props.pinnedProcesses.some((x) => x.id === props.process.id))

async function requestCancelation() {
  const confirmed = await showConfirmModal(
    'Prozess abbrechen',
    `Sind Sie sicher, dass Sie den Prozess "${props.process.name}" abbrechen möchten?`,
    {
      type: 'error',
      positiveText: 'Prozess abbrechen',
      negativeText: 'Nein',
    },
  )
  if (!confirmed) {
    return
  }

  // eslint-disable-next-line vue/no-mutating-props
  props.process.cancelation_requested = true

  axios.post(route('processes.request-cancelation', props.process.id))
}

const closed = ref(false)
function close() {
  if (isPinned.value) {
    filterInPlace(page.props.pinnedProcesses, (x) => x.id !== props.process.id)
  }

  closed.value = true
  emit('close')

  axios.post(route('processes.dismiss', props.process.id))
}

const ERROR_TYPE_LABELS: Record<App.Enums.ProcessErrorType, string> = {
  flash: 'Nutzerfehler',
  internal: 'Interner Fehler',
}
</script>

<script lang="ts">
export function computeStatusForProcess(process: App.Data.Process.ProcessData) {
  if (process.failed_at) return 'failed'
  if (process.completed_at) return 'completed'
  if (process.canceled_at) return 'canceled'
  if (process.timed_out_at) return 'timedOut'
  if (process.started_at) return 'started'
  return 'pending'
}
</script>

<template>
  <div
    v-if="!closed"
    v-auto-animate
    class="relative rounded-2xl border-2 p-3 text-xs shadow transition-[background,border,transform]"
    :class="{
      'border-red-500 bg-red-50 dark:bg-red-950': status === 'failed' || status === 'timedOut',
      'border-emerald-500 bg-emerald-50 dark:bg-emerald-950': status === 'completed',
      'border-yellow-500 bg-yellow-50 dark:bg-yellow-950': status === 'started',
      'border-zinc-300 bg-zinc-50 dark:border-zinc-750 dark:bg-zinc-900': status === 'pending',
      'border-stone-500 bg-stone-50 dark:bg-stone-900': status === 'canceled',
      'scale-95 opacity-50': process.cancelation_requested && status === 'started',
    }"
  >
    <h3 class="mb-2 flex items-center gap-3 text-lg font-semibold">
      <div
        :class="{
          'animate-pulse': status === 'pending',
          'animate-spin': status === 'started',
        }"
      >
        <IUimProcess v-if="status === 'pending' || status === 'started'" class="-scale-x-100" />

        <IPajamasTaskDone v-else-if="status === 'completed'" class="text-emerald-500" />

        <IMaterialSymbolsError v-else-if="status === 'failed'" class="text-red-500" />

        <IIconParkOutlineSleep v-else-if="status === 'timedOut'" class="text-red-500" />

        <IMaterialSymbolsCancel v-else-if="status === 'canceled'" class="text-stone-500" />
      </div>

      {{ process.name }}{{ status === 'pending' || status === 'started' ? '...' : '' }}
    </h3>

    <TuiBadge v-if="status === 'failed'" color="red">Fehlgeschlagen</TuiBadge>

    <TuiBadge v-else-if="status === 'completed'" color="emerald">Fertig</TuiBadge>

    <TuiBadge v-else-if="status === 'started'" color="yellow">Läuft...</TuiBadge>

    <TuiBadge v-else-if="status === 'canceled'" color="white">Abgebrochen</TuiBadge>

    <TuiBadge v-else-if="status === 'timedOut'" color="red">Abgelaufen</TuiBadge>

    <TuiBadge v-else color="white">Bevorstehend</TuiBadge>

    <div v-if="status === 'pending' || status === 'started'" class="relative mt-3">
      <TuiProgressLinear
        :color="status === 'pending' ? 'gray' : 'yellow'"
        :indeterminate="process.total_units === null"
        :value="100 * tweenedProgessValue"
        class="rounded-full"
      />

      <p
        v-if="process.custom_status"
        class="absolute inset-x-0 top-[-1.125rem] text-center text-[11px] font-semibold text-yellow-500 opacity-70"
      >
        {{ process.custom_status }}
      </p>

      <p
        v-if="process.total_units !== null"
        class="absolute right-0 top-[-1.125rem] text-[11px] text-yellow-500 opacity-70"
      >
        {{ Math.floor(tweenedProgessValue * process.total_units) }} / {{ process.total_units }}
      </p>
    </div>

    <p
      v-if="process.error"
      class="mt-3 max-h-[8.5rem] overflow-y-auto whitespace-pre-line text-red-700 dark:text-red-300"
    >
      <template v-if="process.error.type === 'internal'">
        <b>{{ ERROR_TYPE_LABELS[process.error.type] }}.</b>
      </template>

      <template v-else>
        <b>{{ ERROR_TYPE_LABELS[process.error.type] }}:</b>

        <br v-if="process.error.message.includes('\n')" />

        {{ process.error.message }}
      </template>
    </p>

    <p class="mt-2 opacity-50">Gestartet am {{ toGermanString(process.created_at) }}</p>

    <p v-if="status === 'completed'" class="font-semibold opacity-50">
      Prozess dauerte
      {{
        formatShortDuration(
          differenceInSeconds(parseISO(process.completed_at!), parseISO(process.started_at!)),
        )
      }}
    </p>

    <p v-if="status === 'failed'" class="font-semibold opacity-50">
      Prozess lief
      {{
        formatShortDuration(
          differenceInSeconds(parseISO(process.failed_at!), parseISO(process.started_at!)),
        )
      }}
    </p>

    <p v-if="status === 'canceled'" class="font-semibold opacity-50">
      Prozess nach
      {{
        formatShortDuration(
          differenceInSeconds(parseISO(process.canceled_at!), parseISO(process.started_at!)),
        )
      }}
      abgebrochen
    </p>

    <div class="absolute right-3 top-3 flex gap-1.5">
      <template v-if="status === 'pending' || status === 'started'">
        <button
          v-if="!isPinned"
          v-tooltip="'Anheften'"
          type="button"
          class="opacity-50 transition-opacity hover:opacity-100"
          @click="pin"
        >
          <IFluentPin12Regular class="size-5" />
        </button>

        <button
          v-else
          v-tooltip="'Abgeheftet'"
          type="button"
          class="opacity-80 transition-opacity hover:opacity-100"
          @click="unpin"
        >
          <IFluentPin12Filled class="size-5" />
        </button>

        <button
          v-tooltip="'Abbrechen'"
          :disabled="process.cancelation_requested"
          type="button"
          class="opacity-50 transition-opacity hover:opacity-100"
          :class="{
            'pointer-events-none !opacity-25': process.cancelation_requested,
          }"
          @click="requestCancelation"
        >
          <IMdiCancel class="size-5" />
        </button>
      </template>

      <button
        v-if="status !== 'pending' && status !== 'started'"
        v-tooltip="'Für alle schließen'"
        type="button"
        class="opacity-50 transition-opacity hover:opacity-100"
        @click="close"
      >
        <IHeroiconsXMark class="size-5" />
      </button>
    </div>
  </div>

  <template v-else />
</template>
