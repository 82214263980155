import { router } from '@inertiajs/vue3'
import axios, { isAxiosError } from 'axios'
import { onBeforeUnmount, ref } from 'vue'

export interface ErrorModalData {
  title: string
  message: string
}

export interface ErrorFlashData {
  message: string
}

export function useErrorFlashs() {
  const errorFlash = ref<ErrorFlashData>()
  const errorModal = ref<ErrorModalData>({
    title: '',
    message: '',
  })

  const showErrorFlash = ref(false)
  const showErrorModal = ref(false)

  const removeInvalidListener = router.on('invalid', (event) => {
    if (event.detail.response.status === 422) {
      event.preventDefault()

      if (event.detail.response.data.type === 'flash') {
        errorFlash.value = {
          message: event.detail.response.data.message,
        }
        showErrorFlash.value = true
      } else if (event.detail.response.data.type === 'modal') {
        errorModal.value = {
          title: event.detail.response.data.title,
          message: event.detail.response.data.message,
        }
        showErrorModal.value = true
      }
    }
  })

  const axiosInterceptor = axios.interceptors.response.use(undefined, function (error) {
    if (isAxiosError(error) && error.response?.status === 422) {
      if (error.response.data?.type === 'flash') {
        errorFlash.value = {
          message: error.response.data.message,
        }
        showErrorFlash.value = true
      } else if (error.response.data?.type === 'modal') {
        errorModal.value = {
          title: error.response.data.title,
          message: error.response.data.message,
        }
        showErrorModal.value = true
      }
    }

    return Promise.reject(error instanceof Error ? error : new Error(String(error)))
  })

  onBeforeUnmount(removeInvalidListener)
  onBeforeUnmount(function () {
    axios.interceptors.response.eject(axiosInterceptor)
  })

  return { errorFlash, showErrorFlash, errorModal, showErrorModal }
}
