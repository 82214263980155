<template>
  <div>
    <svg
      id="Ebene_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="30 0 142 64"
      style="enable-background: new 0 0 200 64"
      xml:space="preserve"
    >
      <path
        fill="currentColor"
        d="M90.4,42.3L88,54.1H74.2l2.4-11.8H90.4z M83.2,9.9h39.7l-2,8.8H95.7l-2,8.9h25.2l-2.1,8.8H77.2L83.2,9.9z
       M46.2,42.3l-2.5,11.8H30l2.5-11.8H46.2z M39,9.9h39.7l-2,8.8H51.4l-2,8.8h25.2l-2.1,8.8H32.9L39,9.9z M136.1,42.3l-2.4,11.8h-13.8
      l2.5-11.8H136.1z M161,42.3l4.6,11.8h-15l-4.1-11.8H161z M150.1,18.8h-1.1h-6h-16.1l2-8.8h25.8c0.8,0,3.6,0.1,6.2,0.6
      c1.5,0.3,6.2,1.5,8.2,6.2c0.4,0.8,1,3.2,1,5.7c0,4.5-1.8,9.2-5.6,11.6c-1.9,1.3-5.6,2.3-10.7,2.3h-30.9l2-8.8h24.6
      c3.3,0,5.7-1.4,5.7-4.6c0-0.8-0.2-1.6-0.4-1.9c-0.6-1.6-2.2-2-2.7-2.1C151.2,18.8,150.3,18.8,150.1,18.8z"
      />
    </svg>

    <h1 class="text-xl font-extrabold">disPRO</h1>
  </div>
</template>
