<!-- eslint-disable vue/no-duplicate-attr-inheritance eslint doesn't get it -->
<script lang="ts" setup>
import {
  TuiButton,
  TuiDropdown,
  TuiDropdownHeader,
  TuiDropdownItem,
  TuiDropdownItemGroup,
  TuiSidebar,
  TuiVerticalNav,
  TuiVerticalNavLink,
} from '@clickbar/tailwindui-vue'
import { Link } from '@inertiajs/vue3'
import { ref } from 'vue'

import { showReleaseNotes, latestRelease } from '../ReleaseNotes/ReleaseNotes.vue'
import EnvironmentBanner from '@/Components/EnvironmentBanner.vue'
import DepartmentSelection from '@/Components/Sidebar/DepartmentSelection.vue'
import SearchPalette from '@/Components/Sidebar/SearchPalette.vue'
import SidebarLogo from '@/Components/SidebarLogo.vue'
import { useUser } from '@/Composables'

import type { Navigation, RouteName } from '@/Types'

import IconBuildingOffice from '~icons/heroicons/building-office'
import IconCalendarDays from '~icons/heroicons/calendar-days'
import IconCodeBracket from '~icons/heroicons/code-bracket'
import IconFolder from '~icons/heroicons/folder'
import IconWrenchScrewdriver from '~icons/heroicons/wrench-screwdriver'
import IHeroiconsOutlineViewGridAdd from '~icons/heroicons-outline/view-grid-add'
import IHeroiconsSolidChevronUpDown from '~icons/heroicons-solid/chevron-up-down'
import IHeroiconsSolidSearch from '~icons/heroicons-solid/search'

defineOptions({
  inheritAttrs: false,
})

defineProps<{
  items: Navigation[]
}>()

const user = useUser()

const icons: Record<string, unknown> = {
  'building-office': IconBuildingOffice,
  'calendar-days': IconCalendarDays,
  folder: IconFolder,
  'wrench-screwdriver': IconWrenchScrewdriver,
  'code-bracket': IconCodeBracket,
}

const showSearch = ref(false)

const userNavigationItems: {
  name: string
  routeName: RouteName
  method?: string
  as?: string
}[] = [
  {
    name: 'Profil',
    routeName: 'profile.show',
  },
  {
    name: 'Abmelden',
    routeName: 'logout',
    method: 'post',
    as: 'button',
  },
]

function formatUserRole(role: App.Enums.Role) {
  return { admin: 'Admin', user: 'Benutzer' }[role]
}
</script>

<template>
  <TuiSidebar class="relative h-full" color="primary" v-bind="$attrs">
    <EnvironmentBanner />

    <div
      class="-mt-5 flex justify-end space-x-3 bg-white py-6 pr-4 dark:border-b dark:border-primary-600 dark:bg-primary-700"
    >
      <Link class="items-end text-primary-500 dark:text-primary-200" :href="route('dashboard')">
        <SidebarLogo class="mr-6 h-auto w-24" />
      </Link>
    </div>

    <div class="my-6 space-y-4 px-6">
      <DepartmentSelection />

      <TuiButton size="lg" secondary block color="primary" @click="showSearch = true">
        <span class="">Suche</span>

        <span class="ml-auto flex-none pl-3 text-xs font-semibold">⌘K</span>

        <template #icon="iconProps">
          <IHeroiconsSolidSearch v-bind="iconProps" />
        </template>
      </TuiButton>
    </div>

    <TuiVerticalNav class="!px-6">
      <div v-for="item in items" :key="item.title">
        <TuiVerticalNavLink
          :label="item.title"
          type="inertia-link"
          :active="item.active && !item.children?.some((child) => child.active)"
          class="flex items-center"
          :href="item.children?.some((child) => child.active) ? '#' : item.url"
          :badge="item.attributes?.badge"
        >
          <template v-if="item.attributes.icon" #icon="iconProps">
            <Component :is="icons[item.attributes.icon]" v-bind="iconProps" />
          </template>

          <template v-else #icon="iconProps">
            <IHeroiconsOutlineViewGridAdd v-bind="iconProps" />
          </template>
        </TuiVerticalNavLink>

        <div
          v-if="item.children && (item.active || item.children.some((child) => child.active))"
          class="mt-1 flex justify-between"
        >
          <!-- <div class="w-px my-1 ml-3 mr-2 bg-primary-400" /> -->
          <TuiVerticalNav class="flex-1 !px-0">
            <TuiVerticalNavLink
              v-for="child in item.children"
              :key="child.title"
              :label="child.title"
              type="inertia-link"
              :active="child.active"
              class="flex items-center pl-11"
              :href="child.url"
              :badge="child.attributes.badge"
            />
          </TuiVerticalNav>
        </div>
      </div>
    </TuiVerticalNav>

    <slot />

    <div class="flex-1" />

    <div v-if="user" class="mt-4 px-4">
      <!-- Profile dropdown -->
      <TuiDropdown placement="top-end" class="w-full">
        <template #activator>
          <button
            type="button"
            class="font-mediumtext-primary-100 group mx-1 w-full rounded-md px-3.5 py-2 text-left text-sm hover:bg-primary-600/75 focus:outline-none focus:ring-2 focus:ring-white active:bg-primary-800 active:text-white"
          >
            <span class="flex w-full items-center justify-between">
              <span class="flex min-w-0 items-center justify-between space-x-3">
                <span class="flex min-w-0 flex-1 flex-col">
                  <span class="truncate text-sm font-medium text-white">{{ user.name }}</span>

                  <span class="truncate text-sm text-primary-200">{{
                    formatUserRole(user.role!)
                  }}</span>
                </span>
              </span>

              <IHeroiconsSolidChevronUpDown
                class="ml-1 size-5 shrink-0 text-primary-200 group-hover:text-primary-300"
                aria-hidden="true"
              />
            </span>
          </button>
        </template>

        <TuiDropdownHeader :title="user.name" :value="user.email" />

        <TuiDropdownItemGroup>
          <TuiDropdownItem
            v-for="item in userNavigationItems"
            :key="item.name"
            type="inertia-link"
            :method="item.method"
            :as="item.as"
            :href="route(item.routeName)"
          >
            {{ item.name }}
          </TuiDropdownItem>
        </TuiDropdownItemGroup>

        <TuiDropdownItemGroup v-if="latestRelease">
          <TuiDropdownItem @click="() => showReleaseNotes()">
            <div>
              Versionshinweise
              <p class="text-[11px] font-medium opacity-50">{{ latestRelease.name }}</p>
            </div>
          </TuiDropdownItem>
        </TuiDropdownItemGroup>
      </TuiDropdown>
    </div>
  </TuiSidebar>

  <SearchPalette v-model="showSearch" />
</template>
