<script setup lang="ts">
import {
  TuiAlertModal,
  TuiFlashNotification,
  TuiModalFunctionsHost,
  TuiNotificationHost,
} from '@clickbar/tailwindui-vue'
import * as Sentry from '@sentry/vue'
import { nextTick, ref, watch } from 'vue'

import PinnedProcesses from '@/Components/PinnedProcesses.vue'
import ReleaseNotes from '@/Components/ReleaseNotes/ReleaseNotes.vue'
import { useFlash, useErrorFlashs, useUser } from '@/Composables'

const showNotification = ref(false)
const flash = useFlash()

const { errorFlash, showErrorFlash, errorModal, showErrorModal } = useErrorFlashs()

// Ensure that the user is set in Sentry
const user = useUser()
const sentryUserSet = ref(false)
watch(
  user,
  (newUser) => {
    if (newUser?.id && !sentryUserSet.value) {
      Sentry.setUser({
        id: newUser.id.toString(),
        username: newUser.name,
        email: newUser.email,
      })
      sentryUserSet.value = true
    }
  },
  { immediate: true },
)

watch(flash, async (newValue, oldValue) => {
  if (newValue && oldValue && newValue?.banner !== oldValue?.banner) {
    showNotification.value = false
    await nextTick()
    showNotification.value = true
  }
  if (newValue?.banner) {
    showNotification.value = true
  }
})
</script>

<template>
  <div class="flex h-[100dvh] flex-col">
    <main class="isolate min-h-0 grow">
      <slot />
    </main>

    <TuiModalFunctionsHost />

    <TuiNotificationHost alignment="bottom-right" style="z-index: 60" />

    <TuiFlashNotification
      v-if="flash"
      v-model:show="showNotification"
      :title="flash?.banner"
      :type="flash?.bannerStyle"
      :close-delay="5000"
    />

    <TuiFlashNotification
      v-if="errorFlash"
      v-model:show="showErrorFlash"
      :title="errorFlash.message"
      type="error"
      :close-delay="5000"
    />

    <TuiAlertModal
      v-model:show="showErrorModal"
      max-width="lg"
      positive-text="Schließen"
      type="error"
      closeable
      :title="errorModal.title"
      @click:positive="showErrorModal = false"
    >
      <div class="mb-4 mt-8 text-left text-base text-gray-800">
        {{ errorModal.message }}
      </div>
    </TuiAlertModal>

    <PinnedProcesses />

    <ReleaseNotes />
  </div>
</template>
