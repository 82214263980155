<script lang="ts" setup>
import {
  TuiDropdown,
  TuiDropdownHeader,
  TuiDropdownItem,
  TuiDropdownItemGroup,
} from '@clickbar/tailwindui-vue'
import { router } from '@inertiajs/vue3'
import { route } from 'momentum-trail'
import { computed } from 'vue'

import { useDepartments, useCurrentDepartment } from '@/Composables'

import IHeroiconsBuildingOffice from '~icons/heroicons/building-office'
import IHeroiconsSolidChevronUpDown from '~icons/heroicons-solid/chevron-up-down'

const departments = useDepartments()
const department = useCurrentDepartment()

const departmentLabel = computed(() => {
  if (hasDepartmentSelected.value) {
    return department.value?.name
  }

  return 'Fachbereich'
})

const hasDepartmentSelected = computed(() => {
  return department.value !== null
})

function switchDepartment(department: App.Data.Department.DepartmentData) {
  router.put(route('current-department.update'), {
    department_id: department.id,
  })
}
</script>

<template>
  <div class="w-full space-y-2">
    <TuiDropdown placement="bottom-start" class="w-full" fixed>
      <template #activator>
        <button
          type="button"
          class="group flex w-full rounded-md p-2 text-left text-primary-200 hover:bg-primary-600/75 focus:outline-none focus:ring-2 focus:ring-white active:bg-primary-800 active:text-white"
        >
          <IHeroiconsBuildingOffice
            class="mr-3 size-6 shrink-0"
            :class="[
              hasDepartmentSelected
                ? 'text-white'
                : 'text-primary-200 group-hover:text-primary-100',
            ]"
            aria-hidden="true"
          />

          <span class="flex w-full items-center justify-between">
            <span
              class="w-full flex-1"
              :class="{ 'font-bold text-white': hasDepartmentSelected }"
              >{{ departmentLabel }}</span
            >

            <IHeroiconsSolidChevronUpDown class="size-5 shrink-0 text-gray-400" />
          </span>
        </button>
      </template>

      <TuiDropdownItemGroup v-if="hasDepartmentSelected">
        <TuiDropdownHeader title="Ausgewählter Fachbereich:" :value="department?.name ?? ''" />

        <TuiDropdownItem type="inertia-link" :href="route('members.index')">
          {{ department?.name }} verwalten
        </TuiDropdownItem>
      </TuiDropdownItemGroup>

      <TuiDropdownItemGroup v-if="!hasDepartmentSelected">
        <TuiDropdownHeader title="Ausgewählter Fachbereich:" value="Kein Fachbereich ausgewählt" />

        <TuiDropdownItem disabled>Fachbereich verwalten</TuiDropdownItem>
      </TuiDropdownItemGroup>

      <TuiDropdownItemGroup>
        <TuiDropdownItem
          v-for="d in departments"
          :key="d.id"
          type="button"
          @click="switchDepartment(d)"
        >
          {{ d.name }}
        </TuiDropdownItem>

        <TuiDropdownItemGroup>
          <TuiDropdownItem type="inertia-link" :href="route('departments.index')">
            Fachbereiche verwalten
          </TuiDropdownItem>
        </TuiDropdownItemGroup>
      </TuiDropdownItemGroup>
    </TuiDropdown>
  </div>
</template>
