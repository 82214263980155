import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { onMounted, onUnmounted } from 'vue'

import type { Options } from 'pusher-js'

// Do not remove these, as they have to be included to be initialized properly
// for Laravel Echo
const _pusher = Pusher

function instantiateEcho() {
  const key = import.meta.env.VITE_PUSHER_APP_KEY as string
  const options: Options = {
    wsHost: import.meta.env.VITE_PUSHER_HOST as string,
    wsPort: Number(import.meta.env.VITE_PUSHER_PORT),
    wssPort: Number(import.meta.env.VITE_PUSHER_PORT),
    forceTLS: import.meta.env.VITE_PUSHER_SCHEME !== 'http',
    cluster: 'NOTUSED',
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: '/broadcasting/auth',
    userAuthentication: {
      endpoint: '/broadcasting/user-auth',
      headers: {},
      transport: 'ajax',
    },
  }
  const pusher = new Pusher(key, options)

  const echo = new Echo({
    broadcaster: 'reverb',
    key,
    encrypted: true,
    ...options,
    client: pusher,
  })

  echo.connector.pusher.connection.strategy.transports.wss.transport.manager.livesLeft =
    Number.POSITIVE_INFINITY

  return echo
}
export const echo = instantiateEcho()

export function useProcessUpdateListener(
  processId: number,
  callback: (event: App.Data.Process.ProcessData) => unknown,
) {
  const callbackWrapper = (event: { processData: App.Data.Process.ProcessData }) =>
    callback(event.processData)

  const channel = echo.private(`processes.${processId}.update`)
  onMounted(() => {
    channel.listen('ProcessUpdate', callbackWrapper)
  })
  onUnmounted(() => {
    channel.stopListening('ProcessUpdate', callbackWrapper)
  })
}
