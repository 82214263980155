<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3'
import { useStorage } from '@vueuse/core'
import { computed, watch } from 'vue'

import { computeStatusForProcess } from './ProcessCard.vue'
import ProcessCard from '@/Components/ProcessCard.vue'
import { withSingularOrPlural } from '@/Utils'

import IHeroiconsChevronDown from '~icons/heroicons/chevron-down'
import IHeroiconsChevronUp from '~icons/heroicons/chevron-up'
import IMaterialSymbolsError from '~icons/material-symbols/error'
import IPajamasTaskDone from '~icons/pajamas/task-done'
import IUimProcess from '~icons/uim/process'

const page = usePage()
const processes = computed(() => page.props.pinnedProcesses)

const closed = useStorage('process-pins-closed', false)

watch(
  () => processes.value.length,
  (newLength, oldLength) => {
    if (oldLength === 0 && newLength > 0) {
      closed.value = false
    }
  },
)

const processStatuses = computed(() => {
  return processes.value.map((x) => computeStatusForProcess(x))
})

const overallStatus = computed(() => {
  if (processStatuses.value.includes('pending') || processStatuses.value.includes('started')) {
    return 'working'
  }
  if (processStatuses.value.includes('failed')) {
    return 'failed'
  }
  return 'finished' // Can't call it 'completed' as it also includes canceled
})
</script>

<template>
  <div v-if="processes.length > 0" class="absolute right-0 top-0">
    <button
      v-show="closed"
      type="button"
      class="group box-content flex h-5 w-80 items-center rounded-bl-lg border-b border-l border-zinc-300 bg-white px-3 text-xs font-semibold shadow dark:border-zinc-700 dark:bg-zinc-950"
      @click="closed = false"
    >
      <div
        :class="{
          'animate-spin': overallStatus === 'working',
        }"
      >
        <IUimProcess v-if="overallStatus === 'working'" class="-scale-x-100 text-yellow-500" />

        <IMaterialSymbolsError v-else-if="overallStatus === 'failed'" class="text-red-500" />

        <IPajamasTaskDone v-else-if="overallStatus === 'finished'" class="text-emerald-500" />
      </div>

      <p class="ml-2 flex-1">
        {{ withSingularOrPlural(processes.length, 'angehefteter Prozess', 'angeheftete Prozesse') }}
      </p>

      <div class="opacity-50 transition-opacity group-hover:opacity-100">
        <IHeroiconsChevronDown class="size-5" />
      </div>
    </button>

    <div v-show="!closed" class="mr-2 mt-2 flex justify-center">
      <button
        type="button"
        class="opacity-50 transition-opacity hover:opacity-100"
        @click="closed = true"
      >
        <IHeroiconsChevronUp class="size-5" />
      </button>
    </div>

    <!-- Use v-show to ensure the processes still get real-time updates -->
    <div v-show="!closed" v-auto-animate class="mr-2 mt-2 w-96 space-y-2">
      <ProcessCard v-for="process in processes" :key="process.id" :process unpin-on-close />
    </div>
  </div>

  <template v-else />
</template>
