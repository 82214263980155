<script lang="ts" setup>
import {
  TuiCheckbox,
  TuiCommandPalette,
  TuiCommandPaletteFooter,
  TuiCommandPaletteInput,
  TuiCommandPaletteOption,
  TuiDescriptionGrid,
  TuiDescriptionGridItem,
  TuiEmptyState,
  TuiKeyboardKey,
} from '@clickbar/tailwindui-vue'
import { router } from '@inertiajs/vue3'
import { debouncedWatch } from '@vueuse/core'
import axios from 'axios'
import { ref, computed } from 'vue'

import IHeroiconsOutlineEmojiSad from '~icons/heroicons-outline/emoji-sad'
import IHeroiconsOutlineExclamation from '~icons/heroicons-outline/exclamation'
import IHeroiconsOutlineSearch from '~icons/heroicons-outline/search'

defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

interface SearchHit {
  title: string
  subtitle: string | null
  link: string
  options: {
    key: string
    value: string
    label: string
  }[]
}

interface SearchResult {
  hits: SearchHit[]
  estimatedTotalHits: number
  query: string
  limit: number
  offset: number
  processingTimeMs: number
  nbHits: number
  index: string
}

const query = ref('')
const loading = ref(true)
const searchResults = ref<Record<string, SearchResult>>()
const error = ref(false)

const results = computed(() =>
  Object.entries(searchResults.value || {}).map(([label, data]) => ({
    label,
    data,
  })),
)

function onChange(hit: SearchHit) {
  emit('update:modelValue', false)
  router.visit(hit.link)
}

async function performSearch(query: string) {
  loading.value = true
  if (query.length > 0) {
    try {
      const { data } = await axios.get(route('search'), {
        params: { query },
      })
      searchResults.value = data.data
      loading.value = false
    } catch (error_) {
      console.error(error_)
      loading.value = false
      error.value = true
    }
  } else {
    searchResults.value = undefined
    loading.value = true
  }
}

debouncedWatch(query, (query: string) => performSearch(query), { debounce: 250 })
</script>

<template>
  <TuiCommandPalette
    :show="modelValue"
    shortcut-key="k"
    max-width="3xl"
    @update:show="(show: boolean) => emit('update:modelValue', show)"
    @update:model-value="onChange"
  >
    <ul v-if="searchResults" class="">
      <li v-for="result in results" :key="result.label" class="p-2">
        <h2 class="py-2 text-sm font-semibold">
          {{ result.label }}
        </h2>

        <ul class="text-sm text-gray-800">
          <TuiCommandPaletteOption v-for="hit in result.data.hits" :key="hit.title" :value="hit">
            <div>
              <p class="text-sm font-medium">
                {{
                  `${
                    result.label === 'contract' || result.label === 'cleaning_objects' ? '#' : ''
                  }${hit.title}`
                }}
              </p>

              <p v-if="hit.subtitle" class="text-sm">
                {{ hit.subtitle }}
              </p>
            </div>
          </TuiCommandPaletteOption>
        </ul>
      </li>
    </ul>

    <TuiEmptyState
      v-else-if="(query === '' || loading) && !error"
      title="Suche starten"
      subtitle="Geben Sie einen Suchbegriff ein um die Suche zu starten. Sie können die Modifikatoren die in der Fußleiste zu sehen sind verwenden um nur spezifische Objekte zu suchen."
    >
      <template #icon="iconProps">
        <IHeroiconsOutlineSearch v-bind="iconProps" />
      </template>
    </TuiEmptyState>

    <TuiEmptyState
      v-else-if="error"
      title="Fehler"
      subtitle="Bei der Anfrage ist ein Fehler aufgetreten"
    >
      <template #icon="iconProps">
        <IHeroiconsOutlineExclamation v-bind="iconProps" />
      </template>
    </TuiEmptyState>

    <TuiEmptyState
      v-else-if="query !== '' || !loading"
      title="Keine Ergebnisse"
      subtitle="Es wurden keine Ergebnisse für Ihre Suche gefunden"
    >
      <template #icon="iconProps">
        <IHeroiconsOutlineEmojiSad v-bind="iconProps" />
      </template>
    </TuiEmptyState>

    <template #input>
      <TuiCommandPaletteInput v-model="query" placeholder="Suchen" />
    </template>

    <template #preview="{ activeOption }">
      <div
        class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex dark:divide-gray-800"
      >
        <div v-if="activeOption">
          <div class="border-b p-4 dark:border-zinc-700">
            <div class="text-sm font-medium text-gray-900 dark:text-zinc-100">
              {{ activeOption.title }}
            </div>

            <div class="text-sm text-gray-500 dark:text-zinc-200">
              {{ activeOption.subtitle }}
            </div>
          </div>

          <div class="flex flex-auto flex-col justify-between p-4">
            <TuiDescriptionGrid>
              <TuiDescriptionGridItem
                v-for="option in activeOption.options"
                :key="option.key"
                :label="option.label"
              >
                <TuiCheckbox
                  v-if="typeof option.value === 'boolean'"
                  :model-value="option.value"
                  disabled
                />

                <span v-else>{{ option.value }}</span>
              </TuiDescriptionGridItem>
            </TuiDescriptionGrid>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <TuiCommandPaletteFooter>
        <TuiKeyboardKey :active="query.startsWith('>')">></TuiKeyboardKey>
        Fahrzeug
        <TuiKeyboardKey :active="query.startsWith('<')">&lt;</TuiKeyboardKey>
        Werkzeug
        <TuiKeyboardKey :active="query.startsWith('#')">#</TuiKeyboardKey>
        Mitarbeiter
        <TuiKeyboardKey :active="query.startsWith('@')">@</TuiKeyboardKey>
        Benutzer
        <TuiKeyboardKey :active="query.startsWith('-')">-</TuiKeyboardKey>
        Projekt
        <TuiKeyboardKey :active="query.startsWith('_')">_</TuiKeyboardKey>
        Kunde
      </TuiCommandPaletteFooter>
    </template>
  </TuiCommandPalette>
</template>
