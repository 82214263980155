import { addSeconds, differenceInSeconds, startOfDay } from 'date-fns'

export function deepClone<T>(x: T): T {
  if (x === null || typeof x !== 'object') return x
  if (Array.isArray(x)) return x.map((item) => deepClone(item)) as T
  return Object.fromEntries(
    Object.entries(x as object).map(([key, value]) => [key, deepClone(value)]),
  ) as T
}

/** Clamps a value to be in the range [0, 1]. */
export function saturate(value: number) {
  if (value < 0) return 0
  if (value > 1) return 1
  return value
}

/** Clamps a value to be in the range [min, max]. */
export function clamp(value: number, min: number, max: number) {
  if (value < min) return min
  if (value > max) return max
  return value
}

export function lerp(a: number, b: number, t: number) {
  return a * (1 - t) + b * t
}

export function inverseLerp(a: number, b: number, y: number): number {
  return (y - a) / (b - a)
}

export function lerpDates(a: Date, b: Date, t: number) {
  return new Date(lerp(a.getTime(), b.getTime(), t))
}

const hexLerpCache = new Map<string, string>()
export function lerpHexColors(a: string, b: string, t: number) {
  // Since parsing and reconstructing the color can get expensive if done repeatedly, we memoize.
  const key = a + b + ' ' + t
  if (hexLerpCache.has(key)) {
    return hexLerpCache.get(key)!
  }

  // Convert the hex strings into nummbers
  const aNumber = Number(a.replace('#', '0x'))
  const bNumber = Number(b.replace('#', '0x'))

  // Extract channels for both colors
  const aR = aNumber >> 16,
    aG = (aNumber >> 8) & 0xff,
    aB = aNumber & 0xff
  const bR = bNumber >> 16,
    bG = (bNumber >> 8) & 0xff,
    bB = bNumber & 0xff

  // Lerp on a per-channel basis
  const yR = lerp(aR, bR, t)
  const yG = lerp(aG, bG, t)
  const yB = lerp(aB, bB, t)

  // Reconstruct a hex string from the lerped channels
  const result =
    '#' +
    Math.trunc((1 << 24) + (yR << 16) + (yG << 8) + yB)
      .toString(16)
      .slice(1)
  hexLerpCache.set(key, result)

  return result
}

/** Generates a random, uniformly distributed RGB color. */
export function randomHexColor() {
  return (
    '#' +
    Math.floor(Math.random() * 16_777_215)
      .toString(16)
      .padStart(6, '0')
  )
}

export function isProbablyHexColor(value: unknown): value is `#${number}` {
  // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
  return typeof value === 'string' && value[0] === '#'
}

export function formatDuration(seconds: number) {
  if (seconds < 10 * 60 * 60) {
    return `${Math.floor(seconds / 3600)}:${(Math.floor(seconds / 60) % 60)
      .toString()
      .padStart(2, '0')}h`
  } else if (seconds < 24 * 60 * 60) {
    return `${Math.floor(seconds / 3600)}h`
  } else {
    return `${Math.floor(seconds / (24 * 60 * 60))}d`
  }
}

/** Formats durations to "xh ym zs" strings, and unused units are removed completely */
export function formatShortDuration(seconds: number) {
  seconds = Math.floor(seconds)

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const parts = []
  if (hours > 0) parts.push(hours + 'h')
  if (minutes > 0) parts.push(minutes + 'm')
  if (remainingSeconds > 0 || parts.length === 0) parts.push(remainingSeconds + 's')

  return parts.join(' ')
}

/** Parses an string containing simple arithmetic and hh:mm times into seconds. Returns `null` if malformed. */
export function parseFormattedSeconds(string: string): number | null {
  if (!string) return null

  const hhmmRegex = /(\d*):?(\d*)/g
  string = string.replaceAll(
    hhmmRegex,
    (match, p1, p2) => (match ? (60 * (Number(p1) * 60 + Number(p2))).toString() : ''), // ? cuz my regex also matches the empty string hahaha
  )

  if (/[^\d+-\s]/.test(string)) return null

  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const result = new Function('return ' + string)()
    if (result < 0) return null
    return result
  } catch {
    return null
  }
}

/** Rounds `date` with the time of day rounded to the nearest multiple of `step` seconds. */
export function roundDateSeconds(date: Date, step: number) {
  let seconds = differenceInSeconds(date, startOfDay(date))
  seconds = Math.round(seconds / step) * step

  return addSeconds(startOfDay(date), seconds)
}

export function maxLexicographicString(...strings: string[]) {
  if (strings.length === 0) return ''

  return strings.sort()[strings.length - 1]
}

/** Removes duplicates from an array based on a predicate that checks for element equality. */
export function removeDuplicates<T>(array: T[], predicate: (a: T, b: T) => boolean) {
  return array.filter((x, xIndex) => !array.some((y, yIndex) => yIndex < xIndex && predicate(x, y)))
}

/** Returns the expression with the correct grammatical number based on the passed value. */
export function withSingularOrPlural(count: number, singular: string, plural: string) {
  return `${count.toString().replace('Infinity', '∞')} ${count === 1 ? singular : plural}`
}

export function filterInPlace<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
) {
  for (let index = array.length - 1; index >= 0; index--) {
    if (!predicate(array[index], index, array)) array.splice(index, 1)
  }
}

/**
 * Maintains synchronization between a target array and a source array.
 *
 * This function ensures that the target array mirrors the state of the source array, using the provided functions
 * to determine equality, create new elements, update existing ones, and handle deletions. This is basically an Array.map
 * that aims to minimize the changes applied.
 *
 * @param target - The array to be updated
 * @param source - The reference array
 * @param functions - Object containing functions that describe the sync behavior
 */
export function syncArrays<T, U>(
  target: T[],
  source: U[],
  functions: {
    /** Determines if two elements, one from each array, are equal */
    equality: (a: T, b: U, aIndex: number, bIndex: number) => boolean
    /** Creates a new target element from a source element */
    create: (source: U, index: number) => T
    /** Updates an existing target element using a source element */
    update?: (target: T, source: U) => void
    /** Cleans up a target element that is not present in the source array */
    destroy?: (target: T) => void
  },
) {
  const processed = new Set<T>()

  for (const [sourceIndex, sourceElement] of source.entries()) {
    let targetElement = target.find((targetElement, targetIndex) =>
      functions.equality(targetElement, sourceElement, targetIndex, sourceIndex),
    )

    if (targetElement === undefined) {
      targetElement = functions.create(sourceElement, target.length)
      target.push(targetElement)
    } else {
      functions.update?.(targetElement, sourceElement)
    }

    processed.add(targetElement)
  }

  for (let index = 0; index < target.length; index++) {
    const targetElement = target[index]

    if (!processed.has(targetElement)) {
      functions.destroy?.(targetElement)
      target.splice(index, 1)
      index--
    }
  }
}

export function pushUnique<T>(array: T[], item: T) {
  if (!array.includes(item)) array.push(item)
}

export function removeItem<T>(array: T[], item: T) {
  const index = array.indexOf(item)
  if (index !== -1) array.splice(index, 1)
}

/**
 * Hackchamp: Allows one to print a specific element in the DOM without the stuff around it. Works by emptying the body,
 * adding only the element to be printed, then reverting it back. Since it maintains references to the DOM nodes, all state,
 * listeners and references are preserved.
 */
export function printElement(element: HTMLElement): void {
  const body = document.body

  // Store current children of the body in an array
  const originalChildren: Node[] = [...body.children]

  // Clone the element to be printed
  const elementClone = element.cloneNode(true) as HTMLElement

  // Remove all current children from the body
  while (body.firstChild) {
    body.firstChild.remove()
  }

  // Add the cloned element to be printed
  body.append(elementClone)

  // Call window.print and revert the state back after printing
  window.print()

  // Revert the state back
  while (body.firstChild) {
    ;(body.firstChild as HTMLElement).remove()
  }

  for (const child of originalChildren) body.append(child)
}

export class MapWithDefault<X, Y> extends Map<X, Y> {
  private getDefault: () => Y

  constructor(getDefault: () => Y, entries?: readonly (readonly [X, Y])[] | null) {
    super(entries)
    this.getDefault = getDefault
  }

  get(key: X): Y {
    if (!this.has(key)) {
      this.set(key, this.getDefault())
    }
    return super.get(key) as Y
  }
}

export function capitalize(x: string) {
  return x[0].toUpperCase() + x.slice(1)
}

/**
 * Accepts two array parameters and returns an object containing the elements for each array, which are not present in the other array.
 */
export function symmetricDifference<T, U>(
  a: readonly T[] | ReadonlySet<T>,
  b: readonly U[] | ReadonlySet<U>,
): { onlyInA: T[]; onlyInB: U[]; inBoth: (T & U)[] } {
  const setA = a instanceof Set ? (a as Set<T>) : new Set<T>(a)
  const setB = b instanceof Set ? (b as Set<U>) : new Set<U>(b)

  const onlyInA: T[] = [...setA].filter((item) => !setB.has(item as unknown as U))
  const onlyInB: U[] = [...setB].filter((item) => !setA.has(item as unknown as T))

  const inBoth: (T & U)[] = [...setA].filter((item) => setB.has(item as unknown as T & U)) as (T &
    U)[]

  return {
    onlyInA,
    onlyInB,
    inBoth,
  }
}

export function slidingWindows<T>(array: T[], size: number): T[][] {
  return Array.from({ length: array.length - size + 1 }, (_, index) =>
    array.slice(index, index + size),
  )
}

export function assert(x: unknown): asserts x {
  if (!x) {
    throw new Error('Variable should not be falsy')
  }
}

export function toggleInSet<T>(set: Set<T>, item: T) {
  if (set.has(item)) {
    set.delete(item)
  } else {
    set.add(item)
  }
}

export const assertUnreachable = (_: never) => {
  throw new Error('Unreachable')
}
