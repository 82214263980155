<script setup lang="ts">
import { useEnvironment } from '@/Composables'

const environment = useEnvironment()
</script>

<!-- eslint-disable vue/no-root-v-if -->
<template>
  <div
    v-if="environment !== 'production'"
    class="absolute left-[-100px] top-[10px] z-10 w-60 -rotate-45 bg-red-600 text-center text-xs text-white opacity-75 shadow sm:left-[-95px] sm:top-[20px] sm:text-sm dark:bg-red-700"
  >
    {{ environment === 'development' ? 'dev' : environment }}
  </div>
</template>
