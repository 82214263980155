<script lang="ts" setup>
import { computed } from 'vue'

import type { Release } from './ReleaseNotes.vue'

import IHeroiconsChevronLeft from '~icons/heroicons/chevron-left'
import IHeroiconsChevronRight from '~icons/heroicons/chevron-right'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  releases: Release[]
}>()

const selectedIndex = defineModel<number>('selectedIndex', { required: true })

const previousAvailable = computed(() => selectedIndex.value < props.releases.length - 1)
const nextAvailable = computed(() => selectedIndex.value > 0)
</script>

<template>
  <div v-bind="$attrs" class="flex justify-between text-sm">
    <button
      type="button"
      :disabled="!previousAvailable"
      class="flex cursor-pointer select-none items-center gap-2 opacity-60 hover:opacity-100"
      :class="{ 'pointer-events-none !opacity-30': !previousAvailable }"
      @click="selectedIndex++"
    >
      <IHeroiconsChevronLeft />

      <p>{{ releases[selectedIndex + 1]?.name ?? 'Vorherige Version' }}</p>
    </button>

    <button
      type="button"
      :disabled="!nextAvailable"
      class="ml-auto flex cursor-pointer select-none flex-row-reverse items-center gap-2 opacity-60 hover:opacity-100"
      :class="{ 'pointer-events-none !opacity-30': !nextAvailable }"
      @click="selectedIndex--"
    >
      <IHeroiconsChevronRight />

      <p>{{ releases[selectedIndex - 1]?.name ?? 'Nächste Version' }}</p>
    </button>
  </div>
</template>
