import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

declare module '@inertiajs/core' {
  export interface PageProps {
    environment: string
    auth: {
      user: App.Data.User.UserData
    }
    flash: { bannerStyle: 'success' | 'info' | 'warning' | 'error'; banner: string }
  }
}

export function useEnvironment() {
  return computed(() => usePage().props.environment)
}

export function useIsEnvironment(environment: string) {
  return computed(() => useEnvironment().value === environment)
}

export function useUser() {
  return computed(() => usePage().props.auth.user)
}

export function useFlash() {
  return computed(() => usePage().props.flash)
}
