import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useDepartments() {
  const page = usePage()
  return computed(() => (page.props?.departmentList ?? []) as App.Data.Department.DepartmentData[])
}

const departments = useDepartments()
export const departmentsMap = computed(
  () => new Map(departments.value.map((department) => [department.id, department])),
)
